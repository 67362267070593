<!--The content below is only a placeholder and can be replaced.-->
<div style="text-align:center">
  <h1>
    Welcome to {{ title }}!
  </h1>
</div>

<router-outlet></router-outlet>
<!-- <ng-container *ngIf="loginData != ''; else elseTemplate">
  Eingeloggt!
</ng-container>
<ng-template #elseTemplate>
  <app-login></app-login>
</ng-template> -->
