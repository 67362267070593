import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

import { LoginComponent } from './components/login/login.component';
import { HomeComponent } from './components/home/home.component';
import { AuthenticationGuardService as AuthGuard } from './services/authentication/authentication-guard.service';
import { CharacterFormComponent } from './components/character-form/character-form.component';
import { ChroniclesComponent } from './components/chronicles/chronicles.component';
import { GameComponent } from './components/game/game.component';
// import { CreateCharacterComponent } from './character/create-character/create-character.component';
// import { EditCharacterComponent } from './character/edit-character/edit-character.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'characters',
    component: CharacterFormComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always'
	},
	{
		path: 'chronicles',
		component: ChroniclesComponent,
		canActivate: [AuthGuard],
		runGuardsAndResolvers: 'always'
	},
	{
		path: 'game/:chronicleId',
		component: GameComponent,
		canActivate: [AuthGuard],
		runGuardsAndResolvers: 'always'
	},
  {
    path: '**',
    redirectTo: '/',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(
    routes,
    {
      onSameUrlNavigation: 'reload',
      preloadingStrategy: PreloadAllModules,
      enableTracing: false
    }
  )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
