import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../../interfaces/user.interface';
import { CharacterVampireV5 } from '../../interfaces/character-vampire-v5.interface';
import { CommunicationService } from '../../services/communication.service';
import { MappingService } from '../../services/mapping.service';
import { Chronicle } from '../../interfaces/chronicle.interface';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  user: User;
  charId: string;
	char: CharacterVampireV5;
	clans;
	clan: string;
	chronicle: Chronicle;
	storyteller: string;

  constructor(
    private router: Router,
		private communicationService: CommunicationService,
		private mappingService: MappingService
  ) { }

  ngOnInit() {
		this.user = JSON.parse(localStorage.getItem('userInfo'));
    this.charId = JSON.parse(localStorage.getItem('charId'));
    if (!this.charId) {
			return;
		}
    this.communicationService.getChar(this.charId, this.user.UserID).subscribe(response => {
			if (response == null) {
				localStorage.removeItem('charId');
				return;
			}
			this.char = this.mappingService.mapChar(response);
			this.communicationService.getClans().subscribe(resolve => {
				this.clans = resolve;
				this.clan = this.clans.find(c => c.ID == this.char.Header.ClanFK).Name
			});
    });
  }

  userLogout() {
    localStorage.removeItem('userInfo');
    this.router.navigate(['/', 'home']);
  }
}
