<div class="ui segment">
	<h2>
		Welcome {{ user.Firstname }}<button (click)="userLogout()" class="ui inverted red button" id="logoutButton">Logout</button>
	</h2>
	<div class="ui menu">
		<div routerLink="/characters" class="ui button">Character menu</div>
		<div routerLink="/chronicles" class="ui button">Chronicles menu</div>
	</div>
</div>
<div *ngIf="char" class="ui segment">
	<h4>Chosen Character:</h4>
	<div>
		<h3>{{ char.Header.Name }}</h3>
		<div>Clan: {{ clan }}</div>
		<div>XP Total: {{ char.AdditionalInformations.TotalExperience }}</div>
	</div>
</div>
<div *ngIf="chronicle" class="ui segment">
	<h4>Chosen Chronicle:</h4>
	<div>
		<h3>{{ chronicle.Name }}</h3>
		<div>Storyteller: {{ storyteller }}</div>
	</div>
</div>
