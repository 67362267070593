import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CharacterVampireV5 } from '../interfaces/character-vampire-v5.interface';
import { Chronicle } from '../interfaces/chronicle.interface';

@Injectable({
  providedIn: `root`
})
export class CommunicationService {

	api: string = `https://95.223.176.95:8001/api/`;
	timersStarted: Boolean = false;
	errorTimer: any;

  constructor(
		private http: HttpClient
	) { }

	// API
	// GET

	getChars(userId: string): Observable<any> {
    return this.http.get<any>(
      `${this.api}chars/${userId}`
    ).pipe(
      catchError(this.errorHandler)
    );
  }

  getChar(charId: string, userId: string): Observable<any> {
    return this.http.get<any>(
      `${this.api}chars/id/${charId}/${userId}`
    ).pipe(
      catchError(this.errorHandler)
    );
  }

  getSettings(): Observable<any> {
    return this.http.get<any>(
      `${this.api}globalsettings`
    ).pipe(
      catchError(this.errorHandler)
    );
  }

  getUsers(): Observable<any> {
    return this.http.get<any>(
      `${this.api}users`
    ).pipe(
      catchError(this.errorHandler)
    );
  }

	getUser(userId: string): Observable<any> {
    return this.http.get<any>(
      `${this.api}users/id/${userId}`
    ).pipe(
      catchError(this.errorHandler)
    );
	}
	
  getClans(): Observable<any> {
    return this.http.get<any>(
      `${this.api}clans`
    ).pipe(
      catchError(this.errorHandler)
    );
	}
	
	getPredatorTypes(): Observable<any> {
    return this.http.get<any>(
      `${this.api}predatortypes`
    ).pipe(
      catchError(this.errorHandler)
    );
	}

  checkAuthentication(username: string, password: string): Observable<any> {
    return this.http.get<any>(
      `${this.api}authenticate/${username}/${password}`
    ).pipe(
      catchError(this.errorHandler)
    );
	}
	
	getOwnChronicles(userId: string) {
		return this.http.get<any>(
      `${this.api}chronicles/${userId}`
    ).pipe(
      catchError(this.errorHandler)
    );
	}

	getOtherChronicles(userId: string) {
		return this.http.get<any>(
      `${this.api}chronicles/player/${userId}`
    ).pipe(
      catchError(this.errorHandler)
    );
	}

	getTimesPlayerPlayed(chronicleId: string, playerId: string) {
		return this.http.get<any>(
      `${this.api}chronicles/timesplayed/player/${chronicleId}/${playerId}`
    ).pipe(
      catchError(this.errorHandler)
    );
	}

	getLastPlayerPlayed(chronicleId: string, playerId: string) {
		return this.http.get<any>(
      `${this.api}chronicles/lastplayed/player/${chronicleId}/${playerId}`
    ).pipe(
      catchError(this.errorHandler)
    );
	}

	getTimesPlayed(chronicleId: string) {
		return this.http.get<any>(
      `${this.api}chronicles/timesplayed/${chronicleId}`
    ).pipe(
      catchError(this.errorHandler)
    );
	}

	getLastPlayed(chronicleId: string) {
		return this.http.get<any>(
      `${this.api}chronicles/lastplayed/${chronicleId}`
    ).pipe(
      catchError(this.errorHandler)
    );
	}

	getMessages(userId: string, chronicleId: string, lastAskedId: number) {
		return this.http.get<any>(
      `${this.api}messages/${userId}/${chronicleId}/${lastAskedId}`
    ).pipe(
      catchError(this.errorHandler)
    );
	}

	getAccess(chronicleId: string) {
		return this.http.get<any>(
			`${this.api}userchronicle/${chronicleId}`
		).pipe(
      catchError(this.errorHandler)
    );
	}

	getChronicle(chronicleId: string) {
		return this.http.get<any>(
			`${this.api}chronicles/id/${chronicleId}`
		).pipe(
      catchError(this.errorHandler)
    );
	}

	// PUT

	saveChar(char: CharacterVampireV5) {
		// console.log(char);
		return this.http.put<any>(
			`${this.api}chars`, { char }
		).pipe(
			catchError(this.errorHandler)
		);
	}

	updateChronicle(chronicle: Chronicle) {
		return this.http.put<any>(
			`${this.api}chronicles`, { chronicle }
		).pipe(
			catchError(this.errorHandler)
		);
	}

	giveUserAccessAgain(userId: string, chronicleId: string) {
		return this.http.put<any>(
			`${this.api}userchronicle`, { userid: userId, chronicleid: chronicleId, allowed: 1 }
		).pipe(
			catchError(this.errorHandler)
		);
	}

	removeAccess(userId: string, chronicleId: string) {
		return this.http.put<any>(
			`${this.api}userchronicle`, { userid: userId, chronicleid: chronicleId, allowed: 0 }
		).pipe(
			catchError(this.errorHandler)
		);
	}

	// POST

	giveUserAccess(userId: string, chronicleId: string) {
		return this.http.post<any>(
			`${this.api}userchronicle`, { userid: userId, chronicleid: chronicleId }
		).pipe(
			catchError(this.errorHandler)
		);
	}

	createChar(char: CharacterVampireV5) {
		// console.log(char);
		return this.http.post<any>(
			`${this.api}chars`, { char }
		).pipe(
			catchError(this.errorHandler)
		);
	}

	createChronicle(chronicle: Chronicle) {
		return this.http.post<any>(
			`${this.api}chronicles`, { chronicle }
		).pipe(
			catchError(this.errorHandler)
		);
	}

	postDiceThrow(
		chronicle: Chronicle,
		diceNumber: number,
		diceKind: number,
		userId: string,
		charName: string,
		hunger: number
	) {
		// console.log(chronicle);
		return this.http.post<any>(
			`${this.api}dices/throw`, { chronicle, diceNumber, diceKind, userId, charName, hunger }
		).pipe(
			catchError(this.errorHandler)
		);
	}

	// DELETE

	deleteChar(id: string) {
		return this.http.delete<any>(
			`${this.api}chars/${id}`
		).pipe(
			catchError(this.errorHandler)
		);
	}

	deleteChronicle(id: string) {
		return this.http.delete<any>(
			`${this.api}chronicles/${id}`
		).pipe(
			catchError(this.errorHandler)
		);
	}

	// OTHER
  private errorHandler(error: HttpErrorResponse): Observable<any> {
    console.error(`Fehler bei der API-Kommunikation!`);
    return throwError(error);
  }
}
