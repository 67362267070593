import { Pipe, PipeTransform } from '@angular/core';
import { User } from '../interfaces/user.interface';

@Pipe({
  name: 'userfilter'
})
export class UserFilterPipe implements PipeTransform {

  transform(users: User[], filter: string): any {
		if (!filter || !users) {
			return users;
		}
		return users.filter(user => user.Username.includes(filter));
  }
}
