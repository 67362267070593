import { Component, OnInit, Input, OnDestroy, HostListener } from '@angular/core';
import { CommunicationService } from 'src/app/services/communication.service';
import { User } from 'src/app/interfaces/user.interface';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css']
})
export class ChatComponent implements OnInit, OnDestroy {
	@Input() chronicleId: string;

	chatTimer: any;
	messages: any[] = [];
	user: User;
	lastAskedId: number = 0;
	// players: any[] = [];

  constructor(private commService: CommunicationService) { }

  ngOnInit() {
		this.user = JSON.parse(localStorage.getItem('userInfo'));
		this.chatTimer = setInterval(() => {
			this.commService.getMessages(this.user.UserID, this.chronicleId, this.lastAskedId).subscribe(resolve => {
				console.log(resolve);
				if (resolve.length) {
					this.lastAskedId = resolve[resolve.length - 1].MessageID;
					resolve.forEach(m => {
						let message = {
							sender: m.CharacterName,
							whisper: (m.ReceiverFK ? true : false),
							text: m.Message,
							date: new Date(m.CreateDate)
						}
						this.messages.push(message);
					});
					this.messages.sort((a: any, b: any) => {
						return b.date.getTime() - a.date.getTime();
					});
				}
			});
		}, 1000);
  }

	@HostListener('unloaded')
	ngOnDestroy() {
		clearTimeout(this.chatTimer);
	}
}
