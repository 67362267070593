<p>Please Login</p>
<form class="ui form" [formGroup]="loginGroup" (ngSubmit)="submitForm()">
  <div class="fields">
    <div class="field">
      <input formControlName="userName" placeholder="Benutzername" class="ui input" required>
    </div>
    <div class="field">
      <input type="password" formControlName="password" placeholder="Passwort" class="ui input" required>
    </div>
  </div>
  <!-- <input formControlName="password" placeholder="Passwort"> -->
  <button class="ui inverted purple button" type="submit">Login</button>
</form>
<div>{{ errorMessage }}</div>
<p>or Register</p>
