import { Component, OnInit, Input } from '@angular/core';
import { Chronicle } from 'src/app/interfaces/chronicle.interface';
import { CommunicationService } from 'src/app/services/communication.service';
import { User } from 'src/app/interfaces/user.interface';

@Component({
  selector: 'app-chronicle-players',
  templateUrl: './chronicle-players.component.html',
  styleUrls: ['./chronicle-players.component.css']
})
export class ChroniclePlayersComponent implements OnInit {
	@Input() chronicle: Chronicle;

	players: User[];
	searchPlayers: boolean = true;

  constructor(private commService: CommunicationService) { }

  ngOnInit(): void {
		this.renewList();
  }

	removeAccess(userId: string) {
		this.commService.removeAccess(userId, this.chronicle.ID).subscribe(resolve => {
			// console.log(resolve);
			this.renewList();
		});
	}

	renewList() {
		this.players = [] as User[];
		this.commService.getAccess(this.chronicle.ID).subscribe(resolve => {
			resolve.forEach(player => {
				if (player.Allowed) {
					this.commService.getUser(player.UserFK).subscribe(resolve => {
						this.players.push(resolve);
					});
				}
			});
		});
	}
}
