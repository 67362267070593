<div class="ui segment">
	<div class="ui menu">
		<div *ngIf="!showForm" class="ui button" (click)="showChronicleForm()">Create new chronicle</div>
		<div routerLink="/home" class="ui button">Home</div>
	</div>
	<div class="ui segment">
		<h3>Your own chronicles:</h3>
		<div *ngIf="!ownChronicles || !ownChronicles.length">No own chronicles...</div>
		<div *ngFor="let chronicle of ownChronicles; last as l" class="content">
			<div class="ui smallheader">Name: {{ chronicle.Name }} - Times played: {{ chronicle['TimesPlayed'] }} - Last played: {{ chronicle['LastPlayed'] }}</div>
			<div class="ui button" [routerLink]="['/game', chronicle.ID]">Start a session of this chronicle</div>
			<div class="ui button" (click)="editPlayers(chronicle.ID)">Edit players</div>
			<div class="ui button" (click)="showChronicleForm(chronicle.ID)">Edit chronicle</div>
			<div class="ui button" (click)="deleteChronicle(chronicle.ID)">Delete chronicle</div>
			<div *ngIf="showPlayerArea" class="ui segment">
				<app-chronicle-players [chronicle]="chronicle"></app-chronicle-players>
			</div>
			<div class="ui divider" *ngIf="!l"></div>
		</div>
	</div>
	<div class="ui segment">
		<h3>Chronicles you are invited to:</h3>
		<div *ngIf="!otherChronicles || !otherChronicles.length">No invitations...</div>
		<div *ngFor="let chronicle of otherChronicles" class="content">
			<div class="ui smallheader">Name: {{ chronicle.Name }} - Times played: {{ chronicle['TimesPlayed'] }} - Last played: {{ chronicle['LastPlayed'] }}</div>
			<button class="ui button" [routerLink]="['/game', chronicle.ID]" [disabled]="!charId">Join a session of this chronicle</button>
			<div class="ui divider"></div>
		</div>
		<div *ngIf="!charId" class="ui negative message">No character selected</div>
	</div>
	<div *ngIf="showForm" class="ui segment">
		<form class="ui form segment"
					#chronicleForm="ngForm" 
					(ngSubmit)="addChronicle()">
			<div class="three fields">
				<div class="field">
					<div>
						<input	type="text" 
										name="name"
										placeholder="Name of chronicle"
										required
										[(ngModel)]="chronicle.Name"
						/>
					</div>
					<div>
						<select name="maxPlayers"
										[(ngModel)]="chronicle.MaxPlayers"
						>
							<option value="0">Choose maximum players</option>
							<option value="1">1</option>
							<option value="2">2</option>
							<option value="3">3</option>
							<option value="4">4</option>
							<option value="5">5</option>
							<option value="6">6</option>
							<option value="7">7</option>
							<option value="8">8</option>
							<option value="9">9</option>
							<option value="10">10</option>
						</select>
					</div>
					<div>
						<div class="ui segment">
							<div class="field">
								<div class="ui radio checkbox">
									<input type="radio" name="ruleOption" id="v5" (change)="useHouseRules(false)" [(ngModel)]="chronicle.HouseRules" [value]=false />
									<label for="v5">Vampire v5 rules</label>
								</div>
							</div>
							<div class="field">
								<div class="ui radio checkbox">
									<input type="radio" name="ruleOption" id="houseRules" (change)="useHouseRules(true)" [(ngModel)]="chronicle.HouseRules" [value]=true />
									<label for="houseRules">House rules based on d10 (no animation)</label>
								</div>
							</div>
						</div>
						<div *ngIf="chronicle.HouseRules == true" class="ui segment">
							<div class="field">
								<div class="ui radio checkbox">
									<input type="radio" id="twoDoubleTen" name="tenRuleOption" [value]="0" [(ngModel)]="chronicle.TenRule" />
									<label for="twoDoubleTen">Two '10´s' are 4 successes (critical success)</label>
								</div>
							</div>
							<div class="field">
								<div class="ui radio checkbox">
									<input type="radio" id="doubleTen" name="tenRuleOption" [value]="1" [(ngModel)]="chronicle.TenRule" />
									<label for="doubleTen">One '10' are 2 successes</label>
								</div>
							</div>
							<div class="field">
								<div class="ui radio checkbox">
									<input type="radio" id="tenAgain" name="tenRuleOption" [value]="2" [(ngModel)]="chronicle.TenRule" />
									<label for="tenAgain">A '10' is 1 success and is thrown again</label>
								</div>
							</div>
							<div class="ui divider"></div>
							<div class="field">
								<div class="ui checkbox">
									<input type="checkbox" id="hungerExists" name="hungerExistsOption" [(ngModel)]="chronicle.HungerExists" />
									<label for="hungerExists">Hunger dice (only Vampire version 5)</label>
								</div>
							</div>
							<div class="field">
								<div class="ui checkbox">
									<input type="checkbox" id="botchReduces" name="botchReducesOption" [(ngModel)]="chronicle.BotchReduces" />
									<label for="botchReduces">A '1' subtracts a success</label>
								</div>
							</div>
							<div class="ui divider"></div>
							<div class="field">
								<div class="ui checkbox">
									<input type="checkbox" id="difficultyOptional" name="difficultyOptional" [(ngModel)]="difficultyOptional" />
									<label for="difficultyOptional">Difficulty is not a fixed value</label>
								</div>
								<div>
									<label for="difficultyOption">Difficulty</label>
									<input type="number" 
										name="difficultyOption" 
										id="difficultyOption"
										class="option"
										[(ngModel)]="chronicle.Difficulty"
										min="2"
										max="10"
										step="1"
										pattern="[0-9]*"
										inputmode="numeric"
										(keypress)="checkForNumber($event)"
										(change)="validateDifficulty()"
										[disabled]="difficultyOptional"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<button class="ui primary submit button"
							[disabled]="chronicleForm.invalid || chronicle.MaxPlayers < 1"
			>{{ buttonCaption }}</button>
			<button class="ui abort button" (click)="abort()">Cancel</button>
		</form>
	</div>
</div>