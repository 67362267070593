import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { User } from '../../interfaces/user.interface';
import { CommunicationService } from '../../services/communication.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginGroup: FormGroup;
  errorMessage: string;
  // private tempUser: TempUser = {} as TempUser;
  private user: User = {} as User;

  // @Output() submitLogin = new EventEmitter<TempUser>(); // ILoginData

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private commService: CommunicationService
  ) { }

  ngOnInit() {
    this.initForm();
  }

  private initForm() {
    if (this.loginGroup) {
      return;
    }

    this.loginGroup = this.formBuilder.group({
      userName: [''],
      password: ['']
    });
  }

  submitForm() {
		const formValue = this.loginGroup.value;
    this.commService.checkAuthentication(formValue.userName, formValue.password).subscribe(resolve => {
			console.log(resolve);
			this.user = resolve;
			localStorage.setItem('userInfo', JSON.stringify(this.user));
			this.router.navigate(['/', 'home']);
			if (resolve == '') {
				// User anzeigen was falsch gelaufen ist!
				if (resolve.error.status === 401) {
					this.errorMessage = `Fehler bei der Authentifizierung! Bitte prüfen Sie ihre Eingaben!`;
				} else {
					this.errorMessage = `Unbekannter Fehler: ${resolve.error.statusText}`;
				}
			}
    });
  }
}
