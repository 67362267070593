import { Component, OnInit, Input } from '@angular/core';
import { CommunicationService } from 'src/app/services/communication.service';
import { User } from 'src/app/interfaces/user.interface';
import { CharacterVampireV5 } from 'src/app/interfaces/character-vampire-v5.interface';
import { Chronicle } from 'src/app/interfaces/chronicle.interface';

@Component({
  selector: 'app-dice-area',
  templateUrl: './dice-area.component.html',
  styleUrls: ['./dice-area.component.css']
})
export class DiceAreaComponent implements OnInit {
	@Input() chronicleId: string;
	@Input() char: CharacterVampireV5;
	@Input() isGameMaster: Boolean;
	
	hungerOverride: number = -1;
	diceNumber: number;
	user: User;
	showResult: Boolean = false;
	throwResult: number[];
	chronicle: Chronicle;

  constructor(
		private commService: CommunicationService
	) { }

  ngOnInit() {
		this.diceNumber = 4;
		this.user = JSON.parse(localStorage.getItem('userInfo'));
		this.commService.getChronicle(this.chronicleId).subscribe(resolve => {
			// console.log(resolve)
			this.chronicle = resolve;
		});
  }

  checkForNumber(event: any) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  validateDiceNumber() {
    if (this.diceNumber > 99) {
      this.diceNumber = 99;
    } else if (this.diceNumber < 1) {
      this.diceNumber = 1;
    }
  }

  getDiceResults() {
		this.throwResult = [];
		let name: string;
		let hunger: number;
		if (this.isGameMaster) {
			name = 'GameMaster';
			hunger = this.hungerOverride;
		} else {
			name = this.char.Header.Name;
			hunger = this.char.Status.Hunger;
		}
		// console.log(this.chronicle)
		this.commService.postDiceThrow(
			this.chronicle,
			this.diceNumber,
			10,
			this.user.UserID,
			name,
			hunger
		).subscribe(resolve => {
			this.showThrow(resolve.throwResult);
		});
	}
	
	showThrow(results: number[]) {
		this.showResult = true;
		results.forEach(die => {
			this.throwResult.push(die);
		});
	}

	// animateDice (resultDie, red = true, startInterval = 10, decreaseInterval = 5, stopInterval = 500) {
	// 	/*
	// 		Schwarz: 0 = Erfolg 1 = Fehlschlag 2 = Erfolg 3 = ERFOLG 10! 4 = Fehlschlag 5 = Fehlschlag 6 = Erfolg 7 = Fehlschlag 8 = Fehlschlag 9 = Erfolg
	// 		Schwarz-Positionen: 6=1050, 2=945, 8=840, 10=735, 4=630, 1=525, 7=420, 3=315, 5=210, 9=105
	// 		Rot: 0 = BOTCH!!! 1 = Fehlschlag 2 = Erfolg 3 = Fehlschlag 4 = Erfolg 5 = ERFOLG 10! 6 = Fehlschlag 7 = Erfolg 8 = Fehlschlag 9 = Erfolg
	// 		Rot-Positionen: 1=1050, 3=945, 7=840, 5=735, 9=630, 10=525, 4=420, 6=315, 2=210, 8=105
	// 		Mit den momentanen Einstellungen (start:10, decrease:5, stop:500), werden genau 20 Zahlen durchlaufen
	// 		Start also bei -105
	// 	*/
	// 	let xPositionDifference = 105; // Fester Wert festgelegt durch Datei
	// 	// TODO: Position anhand resultDie ermitteln: 
	// 	let xPosition = (resultDie * 105);
	// 	let interval = startInterval;
	// 	let yPosition = 0; // standard "black"
	// 	if (red)
	// 		yPosition = 90;
	// 	let diceAnimationTimer = () => {
	// 		console.log(`Position ${xPosition}:${yPosition}`);
	// 		document.querySelector('#image').style.backgroundPosition = `${xPosition}px ${yPosition}px`;
	// 		if (xPosition < 1046){
	// 			xPosition += xPositionDifference;
	// 		} else {
	// 			xPosition = 105;
	// 		}
	// 		decreaseInterval += 2;
	// 		interval += decreaseInterval;
	// 		if(interval >= stopInterval)
	// 			return;
	// 		setTimeout(diceAnimationTimer, interval);
	// 	}
	// 	setTimeout(diceAnimationTimer, interval);
	// }
}
