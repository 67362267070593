import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Chronicle } from 'src/app/interfaces/chronicle.interface';
import { CommunicationService } from 'src/app/services/communication.service';
import { User } from 'src/app/interfaces/user.interface';

@Component({
  selector: 'app-player-search',
  templateUrl: './player-search.component.html',
  styleUrls: ['./player-search.component.css']
})
export class PlayerSearchComponent implements OnInit {
	@Input() chronicle: Chronicle;
	@Output() renewAccessList = new EventEmitter<boolean>();

	players: User[] = [] as User[];
	user: User;
	filterQuery: string = '';

  constructor(private commService: CommunicationService) { }

  ngOnInit(): void {
		this.user = JSON.parse(localStorage.getItem('userInfo'));
		this.getPlayers();
  }

	getPlayers(): void {
		this.commService.getUsers().subscribe(resolveUsers => {
			this.commService.getAccess(this.chronicle.ID).subscribe(resolveAccess => {
				// console.log(resolveAccess);
				resolveUsers.forEach((user: User) => {
					if (this.user.UserID != user.UserID 
						&& !resolveAccess.some(
							uc => uc.UserFK == user.UserID 
							&& uc.Allowed == 1
						)
					) {
						this.players.push(user);
					}
				});
			});
		});
	}

	invitePlayer(userId: string): void {
		this.commService.getAccess(this.chronicle.ID).subscribe(resolveAccess => {
			if (resolveAccess.some(uc => uc.UserFK == userId)) {
				this.commService.giveUserAccessAgain(userId, this.chronicle.ID).subscribe(resolve => {
					console.log(resolve);
					this.removeUser(userId);
					this.onRenewAccessList();
				});
			} else {
				this.commService.giveUserAccess(userId, this.chronicle.ID).subscribe(resolve => {
					console.log(resolve);
					this.removeUser(userId);
					this.onRenewAccessList();
				});
			}
		});
	}

	removeUser(userId: string): void {
		this.players = this.players.filter(p => p.UserID != userId);
	}

	onRenewAccessList() {
		this.renewAccessList.emit(true);
	}
}
