import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CharacterVampireV5 } from 'src/app/interfaces/character-vampire-v5.interface';
import { CommunicationService } from 'src/app/services/communication.service';
import { MappingService } from 'src/app/services/mapping.service';

@Component({
  selector: 'app-edit-character',
  templateUrl: './edit-character.component.html',
  styleUrls: ['./edit-character.component.css']
})
export class EditCharacterComponent implements OnInit {
	@Input() char: CharacterVampireV5;
	@Output() closeEditor = new EventEmitter();

	newChar: boolean = false;
	bane: string;
	clans = [];
	predatorTypes = [];
	submitted: boolean = false;

	attributeArrays = [];
	skillArray = [];

  constructor(
		private commService: CommunicationService,
		private mapService: MappingService
	) { }

  ngOnInit() {
		if(!this.char) {
			this.char = this.mapService.mapNewChar();
			this.newChar = true;
		}
		this.attributeArrays = this.mapService.getAttributes(this.char);
    this.commService.getClans().subscribe(response => {
			this.clans = response;
			this.changeBane(this.char.Header.ClanFK);
		});
		this.commService.getPredatorTypes().subscribe(response => {
			this.predatorTypes = response;
		});
		this.skillArray = this.mapService.getSkills(this.char);
	}
	
  changeAttribute(attribute: string, quantity: number) {
		this.char.Attributes[attribute] = quantity;
		this.attributeArrays = this.mapService.getAttributes(this.char);
	}

	changeSkill(skill: string, quantity: number) {
		if (this.char.Skills[skill] === 1 && quantity === 1) {
			quantity = 0;
		}
		this.char.Skills[skill] = quantity;
		this.skillArray = this.mapService.getSkills(this.char);
	}
	
	changeBane(bane: string) {
		if (bane) {
			this.bane = this.clans.find(i => i.ID === bane).Bane;
		} else {
			this.bane = ``;
		}
	}

	saveChanges() {
		this.char.Status.Health = this.char.Attributes.Stamina + 3;
		this.char.Status.Willpower = this.char.Attributes.Composure + this.char.Attributes.Resolve;
		// TODO: Add humanity editable
		this.char.Status.Humanity = 7;
		// TODO: Add Resonance select
		this.char.AdditionalInformations.Resonance = 'resonance placeholder';
		// TODO: Add BloodPotency select
		this.char.AdditionalInformations.BloodPotency = 3;
		// TODO: Add Experience values
		this.char.AdditionalInformations.TotalExperience = 0;
		this.char.AdditionalInformations.SpentExperience = 0;
		if (this.newChar) {
			this.char.Status.Hunger = 0;
			this.commService.createChar(this.char).subscribe(response => {
				// console.log(response);
				this.newChar = false;
				this.closeEditor.emit();
			});
		} else {
			this.commService.saveChar(this.char).subscribe(response => {
				// console.log(response);
				this.newChar = false;
				this.closeEditor.emit();
			});
		}
	}

	abort() {
		this.char = null;
		this.newChar = false;
		this.closeEditor.emit();
	}
}
