import { Injectable } from '@angular/core';
import { CharacterVampireV5 } from '../interfaces/character-vampire-v5.interface';
import { Chronicle } from '../interfaces/chronicle.interface';
import { TenRule } from '../enums/ten-rule.enum';

@Injectable({
  providedIn: 'root'
})
export class MappingService {

	filledCircle = '../../../assets/images/filledcircle.png';
  emptyCircle = '../../../assets/images/emptycircle.png';

	constructor() { }
	
	mapChars(characters: any[]) {
		const returnArray = [];
		characters.forEach(character => {
			returnArray.push(this.mapChar(character));
		});
		return returnArray;
	}

	mapChar(character: any) {
		return <CharacterVampireV5> {
			CharID: character.CharID,
			UserFK: character.UserFK,
			CreateDate: new Date(),
			LastChangeDate: new Date(),
			Chosen: false,
			Header: {
				Name: character.Name,
				Ambition: character.Ambition,
				ClanFK: character.ClanFK,
				Concept: character.Concept,
				Desire: character.Desire,
				Generation: character.Generation,
				Chronicle: character.Chronicle,
				PredatorTypeFK: character.PredatorTypeFK,
				Sire: character.Sire
			},
			Attributes: {
				Strength: character.Strength,
				Dexterity: character.Dexterity,
				Stamina: character.Stamina,
				Charisma: character.Charisma,
				Manipulation: character.Manipulation,
				Composure: character.Composure,
				Intelligence: character.Intelligence,
				Wits: character.Wits,
				Resolve: character.Resolve
			},
			Skills: {
				Academics: character.Academics,
				Athletics: character.Athletics,
				AnimalKen: character.AnimalKen,
				Awareness: character.Awareness,
				Brawl: character.Brawl,
				Craft: character.Craft,
				Drive: character.Drive,
				Etiquette: character.Etiquette,
				Finance: character.Finance,
				Firearms: character.Firearms,
				Insight: character.Insight,
				Intimidation: character.Intimidation,
				Investigation: character.Investigation,
				Larceny: character.Larceny,
				Leadership: character.Leadership,
				Medicine: character.Medicine,
				Melee: character.Melee,
				Occult: character.Occult,
				Performance: character.Performance,
				Persuasion: character.Persuasion,
				Politics: character.Politics,
				Science: character.Science,
				Stealth: character.Stealth,
				Streetwise: character.Streetwise,
				Subterfuge: character.Subterfuge,
				Survival: character.Survival,
				Technology: character.Technology
			},
			Status: {
				Hunger: character.Hunger,
				Health: character.Health,
				Willpower: character.Willpower,
				Humanity: character.Humanity
			},
			Advantages: [],
			Flaws: [],
			Disciplines: [],
			Convictions: [character.Conviction1, character.Conviction2, character.Conviction3],
			Touchstones: [character.Touchstone1, character.Touchstone2, character.Touchstone3],
			AdditionalInformations: {
				Resonance: character.Resonance,
				BloodPotency: character.BloodPotency,
				TotalExperience: character.ExperienceTotal,
				SpentExperience: character.ExperienceSpent,
				Age: character.Age,
				ApparentAge: character.ApparentAge,
				DateOfBirth: character.DateOfBirth,
				DateOfDeath: character.DateOfDeath,
				Appearance: character.Appearance,
				DistinguishedFeatures: character.DistinguishedFeatures,
				History: character.History
			}
		}
	}

	mapNewChar() {
		const user = JSON.parse(localStorage.getItem('userInfo'));
		return <CharacterVampireV5> {
			CharID: ``,
			UserFK: user.UserID,
			CreateDate: new Date(),
			LastChangeDate: new Date(),
			Chosen: false,
			Header: {
				Name: ``,
				Ambition: ``,
				ClanFK: ``,
				Concept: ``,
				Desire: ``,
				Generation: 13,
				Chronicle: ``,
				PredatorTypeFK: ``,
				Sire: ``
			}, 
			Attributes: {
				Strength: 1,
				Dexterity: 1,
				Stamina: 1,
				Charisma: 1,
				Manipulation: 1,
				Composure: 1,
				Intelligence: 1,
				Wits: 1,
				Resolve: 1
			}, 
			Skills: {
				Academics: 0,
				Athletics: 0,
				AnimalKen: 0,
				Awareness: 0,
				Brawl: 0,
				Craft: 0,
				Drive: 0,
				Etiquette: 0,
				Finance: 0,
				Firearms: 0,
				Insight: 0,
				Intimidation: 0,
				Investigation: 0,
				Larceny: 0,
				Leadership: 0,
				Medicine: 0,
				Melee: 0,
				Occult: 0,
				Performance: 0,
				Persuasion: 0,
				Politics: 0,
				Science: 0,
				Stealth: 0,
				Streetwise: 0,
				Subterfuge: 0,
				Survival: 0,
				Technology: 0
			}, 
			Status: {
				Hunger: 0,
				Health: 1,
				Willpower: 1,
				Humanity: 1
			}, 
			Advantages: [],
			Flaws: [],
			Disciplines: [],
			Convictions: [],
			Touchstones: [], 
			AdditionalInformations: {
				Resonance: ``,
				BloodPotency: 0,
				TotalExperience: 0,
				SpentExperience: 0,
				Age: 0,
				ApparentAge: 0,
				DateOfBirth: new Date(),
				DateOfDeath: new Date(),
				Appearance: ``,
				DistinguishedFeatures: ``,
				History: ``
			}
		}
	}

	getAttributes(char: CharacterVampireV5) {
		return [
			{
				name: 'Physical',
				attributes: [
					{
						name: Object.keys(char.Attributes)[0],
						circles: this.getCircles(char.Attributes.Strength)
					},
					{
						name: Object.keys(char.Attributes)[1],
						circles: this.getCircles(char.Attributes.Dexterity)
					},
					{
						name: Object.keys(char.Attributes)[2],
						circles: this.getCircles(char.Attributes.Stamina)
					}
				]
			},
			{
				name: 'Social',
				attributes: [
					{
						name: Object.keys(char.Attributes)[3],
						circles: this.getCircles(char.Attributes.Charisma)
					},
					{
						name: Object.keys(char.Attributes)[4],
						circles: this.getCircles(char.Attributes.Manipulation)
					},
					{
						name: Object.keys(char.Attributes)[5],
						circles: this.getCircles(char.Attributes.Composure)
					}
				]
			},
			{
				name: 'Mental',
				attributes: [
					{
						name: Object.keys(char.Attributes)[6],
						circles: this.getCircles(char.Attributes.Intelligence)
					},
					{
						name: Object.keys(char.Attributes)[7],
						circles: this.getCircles(char.Attributes.Wits)
					},
					{
						name: Object.keys(char.Attributes)[8],
						circles: this.getCircles(char.Attributes.Resolve)
					}
				]
			}
		];
	}

	getSkills(char: CharacterVampireV5) {
		const tempArray = [];
		for (var propertyName in char.Skills) {
			tempArray.push({
				name: propertyName,
				circles: this.getCircles(char.Skills[propertyName])
			});
		}
		return tempArray;
	}

	getCircles(number = 1) {
		const tempArray = [];
		for (let i = 0; i < 5; i++) {
			if (i < number) {
				tempArray.push(this.filledCircle);
			} else {
				tempArray.push(this.emptyCircle);
			}
		}
		return tempArray;
	}

	createNewChronicle() {
		return <Chronicle> {
			ID: '',
			Name: '',
			GameMasterFK: '',
			MaxPlayers: 0,
			HouseRules: false,
			TenRule: TenRule.TwoTensDouble,
			HungerExists: true,
			BotchReduces: false,
			Difficulty: 6,
			CreateDate: new Date(),
			LastChangeDate: new Date()
		}
	}
}
