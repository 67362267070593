import { Component, OnInit } from '@angular/core';

import { CharacterVampireV5 } from 'src/app/interfaces/character-vampire-v5.interface';
import { User } from 'src/app/interfaces/user.interface';
import { CommunicationService } from 'src/app/services/communication.service';
import { MappingService } from 'src/app/services/mapping.service';

@Component({
  selector: 'app-character-form',
  templateUrl: './character-form.component.html',
  styleUrls: ['./character-form.component.css']
})
export class CharacterFormComponent implements OnInit {

  maxCharacters: number;
  showEditor: boolean = false;
  showList: boolean = true;

  errors = {
    maxCharacters: false
  };

  chars: CharacterVampireV5[];
  charId: string;
  char: CharacterVampireV5;
  user: User;

  constructor(
		private commService: CommunicationService, 
		private mapService: MappingService
	) { }

  ngOnInit() {
    this.commService.getSettings().subscribe(response => {
      this.maxCharacters = response.MaxCharacters;
			// console.log(`MaxCharacters: ${this.maxCharacters}`);
    });
    this.user = JSON.parse(localStorage.getItem('userInfo'));
    this.charId = JSON.parse(localStorage.getItem('charId'));
		this.loadChars();
  }

	loadChars() {
		this.commService.getChars(this.user.UserID).subscribe(response => {
			this.chars = this.mapService.mapChars(response);
      if (this.charId === null) {
				console.log('no char selected');
				// console.log(this.chars);
        return;
      }
      const character = this.chars.find(x => x.CharID === this.charId);
      if (!character) {
        console.log('Character seems so be deleted!');
        // Tell user that character was not found anymore
        return;
      }
      character.Chosen = true;
    });
	}

  charChosen(id: string) {
    this.chars.map(x => x.Chosen = false);
    this.charId = id;
		localStorage.setItem('charId', JSON.stringify(id));
		// console.log(`Saved charId to localStorage: ${JSON.stringify(id)}`);
    const character = this.chars.find(x => x.CharID === id);
    character.Chosen = true;
  }

  charDelete(id: string) {
    // Charakter löschen nach ID
    if (this.charId === id) {
      this.unselectChar();
		}
		this.commService.deleteChar(id).subscribe(response => {
			// console.log(response);
			this.loadChars();
		});
  }

  newChar() {
    if (this.chars.length >= this.maxCharacters) {
      this.errors.maxCharacters = true;
      return;
    }
		// this.unselectChar();
		this.char = null;
    this.showEditor = true;
  }

  unselectChar() {
    this.chars.map(x => x.Chosen = false);
    this.charId = undefined;
    localStorage.removeItem('charId');
  }

  editChar() {
    this.char = this.chars.find(x => x.CharID === this.charId);
    this.showEditor = true;
	}
	
	closeEditor() {
		this.showEditor = false;
		this.loadChars();
	}
}
