<div class="ui grid">
	<div class="four wide column">
		<div *ngIf="players" class="ui list">
			<div *ngFor="let player of players" class="item">
				<button class="ui button" (click)="removeAccess(player.UserID)">Revoke access</button>
				{{ player.Username }}
			</div>
		</div>
	</div>
	<div class="twelve wide column">
		<app-player-search [chronicle]="chronicle" 
			*ngIf="searchPlayers" 
			(renewAccessList)="renewList()">
		</app-player-search>
	</div>
</div>
