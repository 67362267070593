import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
// import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ChatComponent } from './components/chat/chat.component';
import { DiceAreaComponent } from './components/dice-area/dice-area.component';
import { VideoComponent } from './components/video/video.component';
import { LoginComponent } from './components/login/login.component';
import { HomeComponent } from './components/home/home.component';
import { EditCharacterComponent } from './components/edit-character/edit-character.component';
import { CharacterFormComponent } from './components/character-form/character-form.component';
import { CharacterListItemComponent } from './components/character-list-item/character-list-item.component';
import { ChroniclesComponent } from './components/chronicles/chronicles.component';
import { GameComponent } from './components/game/game.component';
import { PlayerSearchComponent } from './components/player-search/player-search.component';
import { ChroniclePlayersComponent } from './components/chronicle-players/chronicle-players.component';
import { UserFilterPipe } from './pipes/user-filter.pipe';

// const apiConfig: SocketIoConfig = { url: 'http://localhost:8000', options: {} };

@NgModule({
  declarations: [
    AppComponent,
    ChatComponent,
    DiceAreaComponent,
    VideoComponent,
    LoginComponent,
    HomeComponent,
    EditCharacterComponent,
    CharacterFormComponent,
    CharacterListItemComponent,
    ChroniclesComponent,
    GameComponent,
    PlayerSearchComponent,
    ChroniclePlayersComponent,
    UserFilterPipe
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    // SocketIoModule.forRoot(apiConfig),
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
