<div class="content">
  <img *ngIf="char.Chosen" src="assets/images/botch.png" class="ui mini left floated image" />
  <p class="header">{{ char.Header.Name }}</p>
  <p class="metadata">
    Hier kann man später diverse Werte anzeigen lassen (EP, Session-Tage, Attribute, Clan, etc.).
    Hunger: {{ char.Status.Hunger }}
    Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe, eius cupiditate! Eos saepe hic perferendis earum ipsam, doloremque maxime nam maiores accusamus nobis at asperiores aspernatur, dignissimos suscipit tenetur soluta.
  </p>
  <div (click)="choose(char.CharID)" *ngIf="!char.Chosen" class="ui button">Auswählen</div>
  <div (click)="delete(char.CharID)" class="ui button">Löschen</div>
</div>
