import { Injectable } from '@angular/core';
// import { JwtHelperService } from '@auth0/angular-jwt';
import { User } from '../../interfaces/user.interface';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor() { } // private jwtHelper: JwtHelperService

  public isAuthenticated(): boolean {
    const userString = localStorage.getItem('userInfo');
    let user: User;
    if (!userString) {
      localStorage.removeItem('userInfo');
    } else {
      user = JSON.parse(userString);
    }
    if (user) {
      return true;
    }
    return false;
  }
  // public isAuthenticated(): boolean {
  //   const token = localStorage.getItem('token');
  //   return !this.jwtHelper.isTokenExpired(token);
  // }
}
