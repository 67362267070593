import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CharacterVampireV5 } from 'src/app/interfaces/character-vampire-v5.interface';

@Component({
  selector: 'app-character-list-item',
  templateUrl: './character-list-item.component.html',
  styleUrls: ['./character-list-item.component.css']
})
export class CharacterListItemComponent implements OnInit {
  @Input() char: CharacterVampireV5;
  @Output() charChosen = new EventEmitter();
  @Output() charDelete = new EventEmitter();

  ngOnInit() {
  }

  choose(id) {
    // console.log('Saved Char to localStorage:');
    // console.log(this.char);
    this.charChosen.emit(id);
  }

  delete(id) {
    this.charDelete.emit(id);
  }
}
