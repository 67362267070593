<form class="ui form segment" 
			#charForm="ngForm" 
			(ngSubmit)="saveChanges()"
>
<!-- Header -->
	<button class="ui primary submit button" [disabled]="charForm.invalid">
		Save changes
	</button>
	<button (click)="abort()" class="ui button">Discard changes</button>
	<div class="ui error message"></div>
	<div class="ui segment">
    <div class="three fields">
      <div class="field">
        <label>Name</label>
				<input	name="name"
								placeholder="required"
								type="text" 
								[(ngModel)]="char.Header.Name" 
								required
				/>
      </div>
      <div class="field">
        <label>Ambition</label>
        <input name="ambition" type="text" [(ngModel)]="char.Header.Ambition">
      </div>
      <div class="field">
        <label>Clan</label>
				<select name="clan" 
								[(ngModel)]="char.Header.ClanFK" 
								(change)="changeBane($event.target.value)"
								required
				>
					<option value="">choose a clan (required)</option>
          <option *ngFor="let clan of clans" [value]="clan.ID">{{ clan.Name }}</option>
        </select>
      </div>
    </div>
    <div class="three fields">
      <div class="field">
        <label>Concept</label>
        <input name="concept" type="text" [(ngModel)]="char.Header.Concept">
      </div>
      <div class="field">
        <label>Desire</label>
        <input name="desire" type="text" [(ngModel)]="char.Header.Desire">
      </div>
      <div class="field">
        <label>Generation</label>
				<select name="generation" [(ngModel)]="char.Header.Generation">
					<option [value]="15">15 (Thin-Blood)</option>
					<option [value]="14">14 (Thin-Blood)</option>
					<option [value]="13">13 (Standard)</option>
					<option [value]="12">12 (Standard Dark-Ages)</option>
					<option [value]="11">11</option>
					<option [value]="10">10</option>
					<option [value]="9">9</option>
					<option [value]="8">8 (Max)</option>
					<option [value]="7">7 (Max Dark-Ages)</option>
					<option [value]="6">6</option>
					<option [value]="5">5</option>
					<option [value]="4">4 (Methusala)</option>
					<option [value]="3">3 (Antedeluvian)</option>
				</select>
      </div>
    </div>
    <div class="three fields">
      <div class="field">
        <label>Chronicle</label>
        <input name="chronicle" type="text" [value]="char.Header.Chronicle">
      </div>
      <div class="field">
        <label>Predator Type</label>
				<select name="predatortype" 
								[(ngModel)]="char.Header.PredatorTypeFK"
								required
				>
					<option value="">choose a predator type (required)</option>
					<option *ngFor="let type of predatorTypes" [value]="type.ID">{{ type.Name }}</option>
        </select>
      </div>
      <div class="field">
        <label>Sire</label>
        <input name="sire" type="text" [value]="char.Header.Sire">
      </div>
    </div>
  </div>
	<!-- Attributes -->
  <h2 class="ui horizontal divider header">Attributes</h2>
  <div class="ui segment">
    <div class="ui three column very relaxed grid">
      <div class="column" *ngFor="let category of attributeArrays; index as i">
        <h3 class="ui header">{{ category.name }}</h3>
        <div *ngFor="let attribute of category.attributes; index as j">
          <span>{{ attribute.name }}</span>
          <div class="ui mini images">
            <img src="{{point}}"
                 (click)="changeAttribute(attribute.name, k + 1)"
                 class="ui image"
                 *ngFor="let point of attribute.circles; index as k"
            />
          </div>
        </div>
      </div>
    </div>
	</div>
	<!-- Skills -->
	<h2 class="ui horizontal divider header">Skills</h2>
  <div class="ui segment">
		<div class="ui three column very relaxed grid">
			<div *ngFor="let skill of skillArray; index as i" class="column">
				<span>{{ skill.name }}</span>
				<div class="ui mini images">
					<img src="{{point}}"
								(click)="changeSkill(skill.name, j + 1)"
								class="ui image"
								*ngFor="let point of skill.circles; index as j"
					/>
				</div>
			</div>
		</div>
	</div>
	<!-- Chronicle Tenets -->
	<div class="ui divider"></div>
	<div class="ui three columns very relaxed grid">
		<div class="column">
			<h3>Chronicle Tenets</h3>
			<textarea [disabled]="true" rows="15">Is being described by the campaign</textarea>
		</div>
		<div class="column">
			<h3>Touchstones</h3>
			<div	class="ui fluid input"
						[ngClass]="{'error': !char.Convictions[0]}">
				<input	type="text" 
								name="touchstone1"
								placeholder="required"
								[(ngModel)]="char.Touchstones[0]" 
								required
				/>
			</div>
			<input	type="text" 
							name="touchstone2"
							placeholder="optional"
							[(ngModel)]="char.Touchstones[1]"
			/>
			<input	type="text" 
							name="touchstone3"
							placeholder="optional"
							[(ngModel)]="char.Touchstones[2]" 
			/>
			<h3>Convictions</h3>
			<div	class="ui fluid input"
						[ngClass]="{'error': !char.Convictions[0]}">
				<input	type="text" 
								name="convictions1"
								placeholder="required"
								[(ngModel)]="char.Convictions[0]" 
								required 
				/>
			</div>
			<div	class="ui input fluid" 
						[ngClass]="{'error': char.Touchstones[1] && !char.Convictions[1] ||
												!char.Touchstones[1] && char.Convictions[1]}">
				<input	type="text" 
								name="convictions2"
								placeholder="optional, optional touchstone needed"
								[(ngModel)]="char.Convictions[1]"
				/>
			</div>
			<div	class="ui input fluid" 
			[ngClass]="{'error': char.Touchstones[2] && !char.Convictions[2] ||
									!char.Touchstones[2] && char.Convictions[2]}">
				<input	type="text" 
								name="convictions3"
								placeholder="optional, optional touchstone needed"
								[(ngModel)]="char.Convictions[2]"
				/>
			</div>
		</div>
		<div class="column">
			<h3>Clan Bane</h3>
			<textarea rows="15" [value]="bane"></textarea>
		</div>
	</div>
</form>
