import { Component, OnInit } from '@angular/core';
import { CommunicationService } from '../../services/communication.service';
import { Chronicle } from '../../interfaces/chronicle.interface';
import { MappingService } from '../../services/mapping.service';
import { User } from '../../interfaces/user.interface';
import { TenRule } from 'src/app/enums/ten-rule.enum';

@Component({
  selector: 'app-chronicles',
  templateUrl: './chronicles.component.html',
  styleUrls: ['./chronicles.component.css']
})
export class ChroniclesComponent implements OnInit {

	ownChronicles: Chronicle[];
	otherChronicles: Chronicle[];
	difficultyOptional: boolean = false;
	showPlayerArea: boolean = false;
	showForm: boolean = false;
	chronicle: Chronicle;
	user: User;
	charId: string;
	buttonCaption: string;

  constructor(
		private commService: CommunicationService,
		private mapService: MappingService,
	) { }

  ngOnInit(): void {
		this.chronicle = this.mapService.createNewChronicle();
		this.user = JSON.parse(localStorage.getItem('userInfo'));
		this.getChronicles();
		this.charId = JSON.parse(localStorage.getItem('charId'));
	}
	
	getChronicles() {
		this.commService.getOwnChronicles(this.user.UserID).subscribe(resolve => {
			this.ownChronicles = resolve;
			this.ownChronicles.forEach(chronicle => {
				this.commService.getTimesPlayed(chronicle.ID).subscribe(resolve => {
					chronicle['TimesPlayed'] = resolve[''];
					// console.log(chronicle);
				});
				this.commService.getLastPlayed(chronicle.ID).subscribe(resolve => {
					if (resolve == null) {
						chronicle['LastPlayed'] = 'Not played yet!';
						return;
					}
					let date = new Date(resolve['CreateDate']);
					chronicle['LastPlayed'] = date.toLocaleString('de');
				});
			});
		});
		this.commService.getOtherChronicles(this.user.UserID).subscribe(resolve => {
			if (resolve.length == 0) {
				return;
			}
			this.otherChronicles = resolve;
			this.otherChronicles.forEach(chronicle => {
				this.commService.getTimesPlayerPlayed(chronicle.ID, this.user.UserID).subscribe(resolve => {
					chronicle['TimesPlayed'] = resolve[''];
					// console.log(chronicle);
				});
				this.commService.getLastPlayerPlayed(chronicle.ID, this.user.UserID).subscribe(resolve => {
					if (resolve == null) {
						chronicle['LastPlayed'] = 'Not played yet!';
						return;
					}
					let date = new Date(resolve['CreateDate']);
					chronicle['LastPlayed'] = date.toLocaleString('de');
				});
			});
		});
	}

	showChronicleForm(chronicleId = null) {
		if (chronicleId != null) {
			this.buttonCaption = 'Save changes';
			this.chronicle = this.ownChronicles.find(c => c.ID == chronicleId);
		} else {
			this.buttonCaption = 'Add chronicle';
			this.chronicle = this.mapService.createNewChronicle();
		}
		this.showForm = true;
	}

	addChronicle() {
		this.chronicle.GameMasterFK = this.user.UserID;
		if (this.difficultyOptional) {
			this.chronicle.Difficulty = undefined;
		}
		if (this.ownChronicles.some(c => c.ID == this.chronicle.ID)) {
			this.commService.updateChronicle(this.chronicle).subscribe(resolve => {
				// console.log(resolve);
				this.getChronicles();
			});
		} else {
			this.commService.createChronicle(this.chronicle).subscribe(resolve => {
				// console.log(resolve);
				this.getChronicles();
			});
		}
		this.showForm = false;
	}

	deleteChronicle(id: string) {
		this.commService.deleteChronicle(id).subscribe(resolve => {
			console.log(resolve);
			this.getChronicles();
		})
	}

	useHouseRules(value: boolean) {
    this.chronicle.HouseRules = value;
    if (!value) {
      // Alle Regeln in Defaultwerte ändern
      this.chronicle.TenRule = TenRule.TwoTensDouble;
      this.chronicle.HungerExists = true;
      this.chronicle.BotchReduces = false;
      this.chronicle.Difficulty = 6;
      this.chronicle.HouseRules = false;
    }
    // console.log(this);
  }
	
  validateDifficulty() {
    if (this.chronicle.Difficulty > 10) {
      this.chronicle.Difficulty = 10;
    } else if (this.chronicle.Difficulty < 2) {
      this.chronicle.Difficulty = 2;
    }
	}
	
  checkForNumber(event) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
	}
	
	abort() {
		this.showForm = false;
	}

	editPlayers(chronicleId: string) {
		this.chronicle = this.ownChronicles.find(c => c.ID == chronicleId);
		this.showPlayerArea = true;
	}
}
