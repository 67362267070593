import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, RouterLink, Router } from '@angular/router';
import { CommunicationService } from '../../services/communication.service';
import { User } from '../../interfaces/user.interface';
import { CharacterVampireV5 } from 'src/app/interfaces/character-vampire-v5.interface';
import { MappingService } from 'src/app/services/mapping.service';

@Component({
  selector: 'app-game',
  templateUrl: './game.component.html',
  styleUrls: ['./game.component.css']
})
export class GameComponent implements OnInit {
	
	chronicleId: string;
	char: CharacterVampireV5;
	user: User;
	isGameMaster: Boolean = false;

  constructor(
		private router: Router,
		private route: ActivatedRoute,
		private commService: CommunicationService,
		private mapService: MappingService
	) { }

  ngOnInit(): void {
		this.user = JSON.parse(localStorage.getItem('userInfo'));
		const charId = JSON.parse(localStorage.getItem('charId'));
		this.chronicleId = this.route.snapshot.paramMap.get('chronicleId');
		this.commService.getOtherChronicles(this.user.UserID).subscribe(resolve => {
			let tempChronicle = resolve.find(c => c.ID == this.chronicleId);
			if (!tempChronicle) {
				this.commService.getOwnChronicles(this.user.UserID).subscribe(resolve => {
					tempChronicle = resolve.find(c => c.ID == this.chronicleId);
					if (!tempChronicle) {
						this.router.navigate(['/home']);
					} else {
						this.isGameMaster = true;
						this.char = null;
					}
				});
			}
		});
		this.commService.getChar(charId, this.user.UserID).subscribe(resolve => {
			this.char = this.mapService.mapChar(resolve);
		});
  }
}
