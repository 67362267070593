<form #ruleForm="ngForm" 
			class="ui form segment"
			(ngSubmit)="getDiceResults()"
>
  <div class="ui segment">
    <label for="diceNumberOption">Number of dice</label>
		<input type="number" 
			min="1"
			max="99"
			step="1"
			name="diceNumberOption"
			id="diceNumberOption"
			[(ngModel)]="diceNumber"
			pattern="[0-9]*"
			inputmode="numeric"
			(keypress)="checkForNumber($event)"
			(change)="validateDiceNumber()"
		/>
		<div class="field" *ngIf="isGameMaster">
			<select name="hungerOverride"
						[(ngModel)]="hungerOverride"
			>
				<option value="-1">No Hunger</option>
				<option value="0">0</option>
				<option value="1">1</option>
				<option value="2">2</option>
				<option value="3">3</option>
				<option value="4">4</option>
				<option value="5">5</option>
			</select>
		</div>
  </div>
  <div class="ui segment">
    <button class="ui primary submit button">Throw dice!</button>
	</div>
</form>
<div *ngIf="showResult" class="ui segment">
	<img	src="../../../assets/images/v5dice.png"
				alt="throwResult" 
				*ngFor="let die of throwResult; index as i"
				class="die"
				[ngClass]="(!isGameMaster && char.Status.Hunger > i 
				|| isGameMaster && hungerOverride > i) ? 'redSide'+die : 'blackSide'+die"
	/>
</div>
