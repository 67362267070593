<div class="characterComplete">
  <div *ngIf="!showEditor">
    <div (click)="newChar()" class="ui button">Neuen Charakter erstellen</div>
		<div (click)="editChar()" class="ui button" *ngIf="charId != undefined">Editieren</div>
		<div routerLink="/home" class="ui button">Zurück</div>
    <p class="ui error message" *ngIf="errors.maxCharacters">Warning! Maximum of characters reached! Please delete a character before creating a new one!</p>
  </div>
  <div class="ui divider"></div>
	<app-edit-character *ngIf="showEditor" 
											[char]="char"
											(closeEditor)="closeEditor()">
	</app-edit-character>
  <div class="ui divider" *ngIf="showEditor"></div>
  <div *ngIf="!showEditor">
		Verfügbare Charaktere:
		<div class="ui middle aligned selection divided list" *ngIf="chars">
			<app-character-list-item class="item"
															*ngFor="let char of chars"
															[char]="char"
															(charChosen)="charChosen($event)"
															(charDelete)="charDelete($event)">
			</app-character-list-item>
		</div>
		<div *ngIf="!chars">
			Es wurden noch keine Charaktere erstellt.
		</div>
	</div>
</div>
